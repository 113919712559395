import React from 'react'
import OlympiadId from '../../Olympiads/olympiadId';
import NavBar from './NavBar/navBar';

export default function UserOlympiadId() {
  return (
    <>
        <NavBar />
        <OlympiadId />
    </>
    
  )
}
