import React from 'react';
// import { Link } from 'react-router-dom';
// import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import TimeLine from './timeLine';
import NavBar from '../AuthenticateLayout/Dashboard/UsersDashboard/NavBar/navBar';
import Olympiads from '../AuthenticateLayout/Olympiads/olympiads'


const WelcomePage = () => {

    return (
        <div>
                <NavBar />
            
                <Olympiads />
                <TimeLine />
         
            

        </div>
       
    );
};

export default WelcomePage;
