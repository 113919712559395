import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Paper , tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from '../../../../../axiosApi';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export default function SeniorTopperListTable( {selectedClass, selectedLevel, id, selectedSchool} ) {

    const [seniorTopperListData, setSeniorTopperListData] = useState([]); 
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {

        const fetchSeniorTopperList = async () => {
            try {
                const token = localStorage.getItem('matrix_token'); 
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(`${backendURL}/matrix/senior-topper/${selectedClass}/${id}/${selectedSchool}/${selectedLevel}`, { headers });
                setSeniorTopperListData(response.data.data)
              
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchSeniorTopperList();
    }, [id, backendURL, selectedClass, selectedLevel, selectedSchool]);

  return (
    <div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
                <h3>Senior Topper List</h3>
            </div>
            <TableContainer component={Paper} sx={{maxHeight: { xs: 'auto', md: 600 }, boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)'}}>
                <Table stickyHeader  style={{ width: '100%', border: '1px solid #ccc', boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)'}}>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell className='tableCell'>Name</StyledTableCell>
                            <StyledTableCell className='tableCell'>Class</StyledTableCell>
                            <StyledTableCell className='tableCell'>School</StyledTableCell>
                            <StyledTableCell className='tableCell'>Obtain Marks</StyledTableCell>
                            <StyledTableCell className='tableCell'>Total Marks</StyledTableCell>
                            <StyledTableCell className='tableCell'>Percentage</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(seniorTopperListData) && seniorTopperListData.map((topper, index) => {
                                return (
                                    
                                    <StyledTableRow key={topper.name}>
                                        <StyledTableCell className='tableCell'>{topper.name}</StyledTableCell>
                                        <StyledTableCell className='tableCell'>{topper.class}</StyledTableCell>
                                        <StyledTableCell className='tableCell'>{topper.school}</StyledTableCell>
                                        <StyledTableCell className='tableCell'>{topper.obtain_marks}</StyledTableCell>
                                        <StyledTableCell className='tableCell'>{topper.total_marks}</StyledTableCell>
                                        <StyledTableCell className='tableCell'>{topper.percentage}</StyledTableCell>
                                    </StyledTableRow>
                                );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
  )
}
