import React from 'react';
import NavBar from './NavBar/navBar';
import Profile from '../../profile';

export default function UserProfile() {
  return (
    <>
        <NavBar />
        <Profile />
    </>
        
  )
}
