import { React, useState } from 'react';
import { Container, Box } from '@mui/material';
import Sidenav from '../sidenav/sidenav';
import OlympiadId from '../../../Olympiads/olympiadId';
import ParticipatesData from './ParticipatesData';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const SingleOlympiad = () => {
    const [showParticipates, setShowParticipates] = useState(false);

    const toggleParticipates = () => {
        setShowParticipates(!showParticipates);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Sidenav />
            <Container sx={{ flexGrow: 1, ml: 2 }}>
                <Box sx={{ mt: 4 }}>
                    <FormControlLabel
                        sx={{
                        display: 'block',
                       
                        }}
                        control={
                        <Switch
                            checked={showParticipates}
                            onClick={toggleParticipates}
                            name="loading"
                            style={{color: '#ac2cc4', backgroundColor: '#ac2cc4 !importtant'}}
                        />
                        }
                        label="Show Olympiad Details"
                    />
                    {showParticipates ? (
                            <OlympiadId />
                            
                        ) : (
                            <>
                              <ParticipatesData />  
                            </>
                        )} 
                </Box>
                
            </Container>
        </Box>
    );
};

export default SingleOlympiad;
