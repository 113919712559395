import React from 'react';
import NavBar from '../AuthenticateLayout/Dashboard/UsersDashboard/NavBar/navBar';
import Sidenav from '../AuthenticateLayout/Dashboard/AdminDashboard/sidenav/sidenav';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';



const NotFound = () => {
  const role = localStorage.getItem('matrix_user') ? JSON.parse(localStorage.getItem('matrix_user')).role : null;
  const token = localStorage.getItem('matrix_token');
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/login');
  }

  return (
    <>
      {role === 1 ? <Sidenav /> :  <NavBar /> }
      <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
        {token != null ?
        <>
          <h1>404 - Not Found</h1>
          <p>The page you are looking for does not exist.</p>
        </>
      :
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
          <h1>You are not LogIn. Please LogIn</h1>
           <Button variant="contained" color='primary' onClick={handleClick} style={{backgroundColor: '#ac2cc4'}}>Login</Button>
        </div>
        
      }
        
    </div>
    </>
   
  );
};

export default NotFound;
