import React from 'react';
import {Outlet, Navigate } from 'react-router-dom';

const AdminPrivateRoute = () => {
    const isAdmin = localStorage.getItem('matrix_user') ? JSON.parse(localStorage.getItem('matrix_user')).role : null;
    const isAuthenticated = isAdmin && isAdmin === 1; 
 
  return (
    isAuthenticated ? <Outlet/> : <Navigate to="/404" replace />
        
  );
}

export default AdminPrivateRoute;