import React from 'react';
import { Container, Grid, CssBaseline } from '@mui/material';
import Level1Graph from './Graphs/Level1Graph';
import Level2Graph from './Graphs/Level2Graph';
import SideNav from './sidenav/sidenav';
import SchoolGraph from './Graphs/schoolGraph';
import ClassGraph from './Graphs/ClassGraph';
import LevelGraph from './Graphs/LevelGraph';

const AdminDashboard = () => {
    return (
        <div>
            <CssBaseline />
            <SideNav />
            <Container maxWidth="lg">
                <Grid container spacing={3} sx={{ mb: 4, marginLeft: '2rem'}}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Level1Graph />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Level2Graph />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <SchoolGraph />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <ClassGraph />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <LevelGraph />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default AdminDashboard;
