import React from 'react'
import Sidenav from './sidenav/sidenav'
import Profile from '../../profile'

export default function adminProfile() {
  return (
    <>
        <Sidenav />
        <Profile />

    </>
  )
}
