import React, { useState, useEffect } from 'react';
import axios from '../../../../axiosApi';
import { Link as RouterLink } from 'react-router-dom';
import { TextField, Box, Button, TableContainer, Table, TableHead, TableBody, Paper, Modal, TableCell, TableRow, tableCellClasses} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export default function CuttOffMarks( { id }) {

    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [message, setMessage] = useState('');
    const [cuffOffMarksData, setCuffOffMarksData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [editedData, setEditedData] = useState({ class: '', cutoff_marks: '' });

    useEffect(() => {
        const fetchCuttoffMarks = async () => {
            try {
                const token = localStorage.getItem('matrix_token'); 
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(`${backendURL}/admin/cutoff/${id}`, { headers });
                 setCuffOffMarksData(response.data.data)
              
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchCuttoffMarks();
    }, [id, backendURL]);

     const handleOpenModal = (row) => {
        setEditedData({ class: row.class, cutoff_marks: row.cutoff_marks });
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEditedData({ ...editedData, [name]: value });
    };

    const handleUpdateCutOffMarks = async () => {
        try {
            const token = localStorage.getItem('matrix_token');
            const headers = {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            };
            const data = {
                cutoff_marks: editedData.cutoff_marks,
                olympiad_id: id,
                class: editedData.class
            }
        const response = await axios.put(`${backendURL}/admin/cutoff`, data, { headers });
          setMessage(response.data.message);
           handleCloseModal();
        } catch (error) {
          console.error('Error', error);
          setMessage('Error in updating cutoff marks')
        }
      };

  return (
    <>
    <Box sx={{  width: 'auto'}}>
        <div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
                {/* <Diversity3Icon /> */}
                <h3>Cuff Off Marks Data</h3>
            </div>
            {message && <p style={{color: 'red'}}>{message}</p>}
            <TableContainer component={Paper} sx={{maxHeight: { xs: 'auto', md: 600 }, boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)'}}>
                <Table stickyHeader  style={{ width: '100%', border: '1px solid #ccc', boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)'}}>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell className='tableCell'>Class</StyledTableCell>
                            <StyledTableCell className='tableCell'>Cutt Off Marks</StyledTableCell>
                            <StyledTableCell className='tableCell'>Action</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(cuffOffMarksData) && cuffOffMarksData.map((marks, index) => {
                                return (
                                    
                                        <StyledTableRow key={marks.id}>
                                            <StyledTableCell className='tableCell'>{marks.class}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>{marks.cutoff_marks}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    component={RouterLink}
                                                    onClick={() => handleOpenModal(marks)}
                                                    style={{backgroundColor: '#ac2cc4', width: '5rem'}}
                                                    >
                                                    Edit
                                                </Button> 
                                                <Modal
                                                    open={openModal}
                                                    onClose={handleCloseModal}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            width: 400,
                                                            bgcolor: 'background.paper',
                                                            boxShadow: 24,
                                                            p: 4,
                                                            borderRadius: '25px'
                                                        }}
                                                    >
                                                        <h2 style={{textAlign: 'center'}}>Edit Cutoff Marks</h2>
                                                        <TextField
                                                            name="class"
                                                            disabled
                                                            label="Class"
                                                            value={editedData.class}
                                                            onChange={handleInputChange}
                                                            fullWidth
                                                            sx={{ mb: 2 }}
                                                        />
                                                        <TextField
                                                            name="cutoff_marks"
                                                            label="Cutoff Marks"
                                                            value={editedData.cutoff_marks}
                                                            onChange={handleInputChange}
                                                            fullWidth
                                                            sx={{ mb: 2 }}
                                                        />
                                                        <Button 
                                                        variant="contained"
                                                        fullWidth
                                                        component={RouterLink}
                                                        style={{backgroundColor: '#ac2cc4', width: '5rem'}}
                                                        onClick={handleUpdateCutOffMarks}>
                                                          Update
                                                        </Button>
                                                    </Box>
                                                </Modal>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </Box>
</>
  )
}
