import React from 'react';

import NavBar from './NavBar/navBar';



const Dashboard = () => {
   
    return (
        <div>
            <NavBar />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div style={{ flex: '1', paddingLeft: '5rem' }}>
                    <h1>Start <span style={{fontSize: '3rem', color: '#ab47bc'}}>Registering</span></h1>
                    <p style={{ width: '25rem', textAlign: 'left', fontStyle: 'italic' }}>Engaging in Olympiads ignites a spark of curiosity and fosters a love for learning that extends far beyond the classroom. It encourages students to explore subjects in-depth, push the boundaries of their knowledge, and develop problem-solving skills that are invaluable in all aspects of life.</p>
                </div>
                <div style={{ flex: '1', padding: '5rem 20px 0px 0px',  }}>
                    <img src="https://images.pexels.com/photos/1326947/pexels-photo-1326947.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Olympiad" style={{ maxWidth: '45rem', height: 'auto' }} />
                </div>
            </div>
             
        </div>
       
    );
};

export default Dashboard;
