import React from 'react'
import Olympiads from '../../Olympiads/olympiads';
import NavBar from './NavBar/navBar';

export default function UserOlympiads() {
  return (
    <>
        <NavBar />
        <Olympiads />
    </>
    
  )
}
