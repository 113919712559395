import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { TextField, Button, Box, Grid, Container, ThemeProvider, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Sidenav from './sidenav/sidenav';
import ModeIcon from '@mui/icons-material/Mode';

function NewCenter() {
  const initialFormData = {
    centre: '',
    centreCity: '',
    centreAddress: '',
    totalSeat: ''
  };

  const fieldNames = ['centre', 'centreCity', 'centreAddress', 'totalSeat'];

  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [matrixToken, setMatrixToken] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const token = localStorage.getItem('matrix_token');
    setMatrixToken(token);
     
  }, []); 


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
        const headers = {
            'Authorization': `Bearer ${matrixToken}`,
            'Content-Type': 'application/json'
          };
      const response = await axios.post(`${backendURL}/admin/centre/create`, formData, {headers});
      setSuccessMessage(response.data.message);
      setFormData(initialFormData); 
    } catch (error) {
      setError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
      <Sidenav />
        <Box
            sx={{ 
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)',
            padding: '1rem 2rem',
            margin: '2rem 0rem',
            borderRadius: '25px'
            }}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
            <ModeIcon  style={{fontSize: '2rem', color: '#ac2cc4'}}/>
            <Typography variant="h5" align="center" style={{fontWeight: '600'}}>Create Centre</Typography>
            </div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {fieldNames.map((fieldName) => (
                <Grid item xs={12} key={fieldName}>
                  <TextField
                    label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name={fieldName}
                    value={formData[fieldName]}
                    onChange={handleChange}
                    required
                    disabled={fieldName === 'centre'}
                    type={fieldName === 'totalSeat' ? 'number' : 'text'}
                    inputProps={{
                        min: fieldName === 'totalSeat' ? 1 : undefined 
                      }}
                  />
                </Grid>
              ))}
            </Grid>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
            <div style={{ display: 'flex', justifyContent: 'center'}}>
                <Button type="submit" variant="contained" disabled={loading} sx={{ mt: 3 }} style={{backgroundColor: '#ac2cc4', display: 'flex', justifyContent: 'center'}}
                >
                Submit
                </Button>
            </div>
            
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default NewCenter;
