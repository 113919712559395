import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import Typography from '@mui/material/Typography';
import Cerfificate from '../../../assets/certificate.png'
import Level1 from '../../../assets/level1.png'


export default function TimeLine() {
  return (
    <Timeline position="alternate">
        <div style={{ display: 'flex', justifyContent: 'center' , alignItems: 'center', gap: '10px'}}>
            <img src={Level1} style={{width: '5rem', height: '5rem'}} alt='level1'/>
            <Typography variant="h4">Level 1</Typography>
        </div>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <HowToRegIcon />
          </TimelineDot>
         
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
           Registration
          </Typography>
          <Typography>Simple & Easy 3 steps. </Typography>
          <Typography>Sign Up, Register and Pay for Olympiad</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <ConfirmationNumberIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Admit Card
          </Typography>
          <Typography>Get Hallticket for Exam</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary" variant="outlined">
            <AssignmentIcon />
          </TimelineDot>
          <TimelineConnector  />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '20px', px: 2 }}>
          <Typography variant="h6" component="span">
            Exam
          </Typography>
          <Typography>Prepare for Exam</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <TurnedInIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '20px', px: 2 }}>
          <Typography variant="h6" component="span">
            Result
          </Typography>
          <Typography>Get the Results</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="secondary" style={{backgroundColor: 'white', borderColor: 'rgb(172, 44, 196)'}}>
            {/* <ApprovalIcon /> */}
            <img src={Cerfificate} style={{width: '24px', height: '24px', padding: '2px'}} alt='certificate'/>
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Cerificate
          </Typography>
          <Typography>Get the Cerificate</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>


  );
}