import React, { useState, useEffect } from 'react';
import axios from '../../../axiosApi';
import { TableContainer, Table, TableHead, TableBody, tableCellClasses, Paper, Button, Pagination, TableCell, TableRow, Tooltip, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NavBar from '../UsersDashboard/NavBar/navBar';
import { styled } from '@mui/material/styles';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';


const InchargeOlympiads = () => {
    const [inchargeOlympiadData, setInchargeOlympiadData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const role= localStorage.getItem('matrix_user') ? JSON.parse(localStorage.getItem('matrix_user')).role : null;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    useEffect(() => {
        const fetchInchargeOlympiadData = async () => {
            try {
                const token = localStorage.getItem('matrix_token'); 
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(`${backendURL}/incharge/olympiad/your-olympiad?page=${currentPage}`, { headers });
                setInchargeOlympiadData(response.data.data.data);
                setPagination(response.data.data);
            } catch (error) {
                console.error('Error fetching Incharge Olympiad data:', error);
            }
        };

        fetchInchargeOlympiadData();
    }, [backendURL, currentPage]);

    const truncateDescription = (description) => {
        return description.length > 30 ? `${description.slice(0, 30)}...` : description;
      };


    return (
        <>
            <NavBar />
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
                    <LocalLibraryIcon style={{color: '#ac2cc4', height: '2rem', width: '2rem'}}/>
                    <h2>Your Olympiad Information</h2>
                </div>
                <TableContainer component={Paper} className='table' style={{width: 'auto', boxShadow: '0 5px 20px 0 rgba(0, 0, 0, .16)' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell className='tableCell'>Olympiad Id</StyledTableCell>
                                <StyledTableCell className='tableCell'>Olympiad Name</StyledTableCell>
                                <StyledTableCell className='tableCell'>Olympiad Description</StyledTableCell>
                                <StyledTableCell className='tableCell'>Start Date</StyledTableCell>
                                <StyledTableCell className='tableCell'>End Date</StyledTableCell>
                                <StyledTableCell className='tableCell'>Registration Deadline</StyledTableCell>
                                <StyledTableCell className='tableCell'>Action</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(inchargeOlympiadData) && inchargeOlympiadData.map((olympiad, index) => (
                                <StyledTableRow key={index}>
                                <StyledTableCell className='tableCell'>{olympiad.olympiad_id}</StyledTableCell>
                                <StyledTableCell className='tableCell'>
                                    <Link
                                        to={
                                            role === 1 ? `/admin/olympiads/${olympiad.olympiad_id}` :
                                            role === 2 ? `/incharge/olympiads/${olympiad.olympiad_id}` :
                                            role === 5 ? `/student/olympiads/${olympiad.olympiad_id}` :
                                            "/404"
                                        }
                                        component={RouterLink}
                                        variant="contained"
                                        color="primary"
                                    
                                    >
                                        {olympiad.participant_olympiad.name}
                                    </Link>
                                </StyledTableCell>
                                <StyledTableCell className='tableCell'>
                                    <Tooltip title={olympiad.participant_olympiad.description}>
                                        <span>{truncateDescription(olympiad.participant_olympiad.description)}</span>
                                    </Tooltip>
                                </StyledTableCell>
                                <StyledTableCell className='tableCell'>{olympiad.participant_olympiad.start_date}</StyledTableCell>
                                <StyledTableCell className='tableCell'>{olympiad.participant_olympiad.end_date}</StyledTableCell>
                                <StyledTableCell className='tableCell'>{olympiad.participant_olympiad.registration_deadline}</StyledTableCell>
                                <StyledTableCell className='tableCell'>
                                    <Button
                                        component={RouterLink}
                                        to={`/incharge/olympiad/${olympiad.olympiad_id}/registered`}
                                        variant="contained"
                                        color="primary"
                                        style={{backgroundColor: '#ac2cc4'}}
                                    >
                                        View
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {pagination && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                        <Pagination
                        count={pagination.last_page}
                        page={currentPage}
                        onChange={(event, value) => setCurrentPage(value)}
                        variant="outlined"
                        shape="rounded"
                        />
                     </div>
                )}
            </div>
        </>
        
    );
};

export default InchargeOlympiads;
