import React, { useEffect, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import axios from '../../axiosApi';
import NavBar from '../Dashboard/UsersDashboard/NavBar/navBar';

function Success() {
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [status, setStatus] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const role = localStorage.getItem('matrix_user') ? JSON.parse(localStorage.getItem('matrix_user')).role : null;
    useEffect(() => {
       
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');
        console.log(sessionId, 'sessionId');

        const token = localStorage.getItem('matrix_token');
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

      
        axios.get(`${backendURL}/success/${sessionId}`, {headers})
            .then(response => {
              
                console.log('Success:', response.data.message);
                setStatus(response.data.status);
                setPaymentStatus(response.data.message);
            })
            .catch(error => {
                
                console.error('Error processing session ID:', error);
            });
    }, [backendURL]);

    return (
        <>
            <NavBar />
             <div>
                {status === 'success' ? (
                    <h1>Success</h1>
                ) : (
                    <h1>Processing</h1>
                )}
                <p>Payment Status: {paymentStatus}</p>
                <Link
                        component={RouterLink}
                        variant="contained"
                        color="primary"
                        to={role === 2 ? '/olympiads' : '/olympiads'}
                    >
                    Olympiads    
                </Link>
            </div>
        </>
       
    );
}

export default Success;
