import React from 'react';
import { Container, Box } from '@mui/material';
import Sidenav from '../sidenav/sidenav';
import Olympiads from '../../../Olympiads/olympiads';

const AdminAllOlympiads = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Sidenav />
            <Container sx={{ flexGrow: 1, ml: 2 }}>
                <Box sx={{ mt: 4 }}>
                    <Olympiads />
                </Box>
            </Container>
        </Box>
    );
};

export default AdminAllOlympiads;
