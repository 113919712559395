import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Box, Container, CssBaseline, Typography, Paper, CircularProgress } from '@mui/material';
import axios from '../../../../axiosApi';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default function ClassGraph() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('matrix_token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            try {
                const response = await axios.get(`${backendURL}/admin/chart/3`, { headers });
                const fetchedData = response.data;

                const chartData = {
                    labels: fetchedData.map(item => item.class),
                    datasets: [
                        {
                            label: 'Student Count',
                            data: fetchedData.map(item => item.total_students),
                            backgroundColor: 'rgba(171, 71, 188, 0.5)',
                            borderColor: 'rgba(171, 71, 188, 1)',
                            borderWidth: 1,
                        },
                    ]
                };

                setData(chartData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [backendURL]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",
            },
            title: {
                display: false,
                text: "Class Data",
                font: {
                    size: 18,
                    weight: "bold",
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Class",
                    font: {
                        size: 14,
                        weight: "bold",
                    },
                },
                grid: {
                    display: false,
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Students Count",
                    font: {
                        size: 14,
                        weight: "bold",
                    },
                },
                grid: {
                    color: "rgba(0, 0, 0, 0.1)",
                },
            },
        },
    };

    return (
        <div>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Class Data
                    </Typography>
                    <Paper elevation={3} sx={{ padding: 2, marginTop: 3, width: '100%', position: 'relative' }}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Bar data={data} options={options} />
                        )}
                    </Paper>
                </Box>
            </Container>
        </div>
    );
}
