import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const instance = axios.create({
  baseURL: backendURL,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      alert('Session expired. Please login again.');
      window.location.href = '/login'; 
    }
    return Promise.reject(error);
  }
);

export default instance;
