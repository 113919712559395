import React, { useState, useEffect } from 'react';
import axios from '../axiosApi';
import NavBar from '../AuthenticateLayout/Dashboard/UsersDashboard/NavBar/navBar';
import { Navigate } from 'react-router-dom';


const VerifyEmail = () => {
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [redirect, setRedirect] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  

  const verifyEmail = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenid = urlParams.get('token-id');
    const email = urlParams.get('email');
    const token = localStorage.getItem('matrix_token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    axios
      .get(`${backendURL}/verify-email/${email}/${tokenid}`, { headers })
      .then((response) => {
        setVerificationStatus('Email verified');
        console.log(response);
        setTimeout(() => {
         setRedirect(true);
        }, 2000); 
      })
      .catch((error) => {
        console.error('Error verifying email:', error.message);
      })
      .finally(() => {
        setLoading(false); 
      });
  };

  useEffect(() => {
    verifyEmail(); 
  }, ); 

  if(redirect){
    return <Navigate to="/dashboard" />;
  }
  return (
    <>
      <NavBar />
      <div>
        {loading ? ( 
          <p>Verifying...</p>
        ) : (
          <p>{`${verificationStatus}`}</p>
        )}
      </div>
    </>
  );
};

export default VerifyEmail;
