import React, { useState, useEffect } from 'react';
import axios from '../axiosApi'
import DoneIcon from '@mui/icons-material/Done';
import { Box, TextField, Button, Table, TableBody, TableCell, TableRow, TableContainer, Paper, Modal, tableCellClasses, Typography } from '@mui/material';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import PortraitIcon from '@mui/icons-material/Portrait';
import { handleLogout } from './Dashboard/AdminDashboard/sidenav/accountButton';

const Profile = () => {

    const [profileData, setProfileData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedProfile, setEditedProfile] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [message, setMessage] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('matrix_token'); 
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(`${backendURL}/profile`, { headers });
                setProfileData(response.data.data);
                setEditedProfile(response.data.data);
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };
        fetchProfileData();

    }, [backendURL]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleUpdate = async () => {
        try {
            const token = localStorage.getItem('matrix_token'); 
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            const allFields = {
                name: editedProfile.name,
                email: editedProfile.email,
                phone: editedProfile.phone,
                father: editedProfile.father,
                mother: editedProfile.mother,
                class: editedProfile.class,
                gender: editedProfile.gender,
                dob: editedProfile.dob,
                city: editedProfile.city,
                district: editedProfile.district,
                state: editedProfile.state,
                pincode: editedProfile.pincode

            };

            await axios.put(`${backendURL}/profile/update`, allFields, { headers });
            setIsEditing(false);
            setProfileData(editedProfile); 
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };


    const handleChange = (e) => {
        setEditedProfile({
            ...editedProfile,
            [e.target.name]: e.target.value
        });
    };

    const handleVerifyEmail = () => {
        const token = localStorage.getItem('matrix_token'); 
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
        axios.get(`${backendURL}/verify-email`, {headers})
            .then(response => {
                console.log(response.data); 
                setMessage(response.data.message)
            })
            .catch(error => {
                console.error('Error verifying email:', error);
            });
    }

    const handleChangePassword = () => {
        setOpenModal(true);
      };
    
      const handleCloseModal = () => {
        setOpenModal(false);
        setOldPassword('');
        setNewPassword('');
      };
    
      const handleSubmitChangePassword = async () => {
        try {
          const token = localStorage.getItem('matrix_token');
          const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          };
      
          const data = {
            old_password: oldPassword,
            new_password: newPassword,
          };
      
          const response = await axios.put(`${backendURL}/change-password`, data, { headers });
          console.log(response, 'response');
          setOpenModal(false);
          console.log('Password changed successfully');
          setMessage('Password changed successfully');
        } catch (error) {
          console.error('Error changing password:', error);
          setMessage("The new password field must be at least 8 characters.");
        }
      };
      

    return (
        <div>
            {profileData ? (
                <div style={{display: 'flex',justifyContent: 'center', margin: 'auto', flexDirection: 'column', width: '35rem', padding: '0rem 1rem 2rem 1rem'}}>
                    
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', marginBottom: '1rem'}}>
                        <PortraitIcon style={{fontSize: '2rem', color: '#ac2cc4'}}/>
                        <Typography component="h1" variant="h5" >
                            Profile Information
                        </Typography>
                    </div>
                    <TableContainer component={Paper} style={{marginBottom: '1rem', boxShadow: '0 5px 20px 0 rgba(0, 0, 0, .16)', overflowX: 'auto'}} className='table'>
                        <Table aria-label="profile table">
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Name</strong></StyledTableCell>
                                    <StyledTableCell>{isEditing ? <input type="text" name="name" value={editedProfile.name} onChange={handleChange} /> : profileData.name}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Email</strong></StyledTableCell>
                                    <StyledTableCell  style={{display: 'flex', alignItems: 'center'}}>{isEditing ? <input type="email" name="email" value={editedProfile.email} onChange={handleChange} /> : profileData.email}
                                    {profileData.email_verified_at === null ? (
                                        <Button onClick={handleVerifyEmail} style={{marginLeft: '2rem', backgroundColor: '#ac2cc4', color: 'white'}}>Verify Email</Button>
                                        ) : (
                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '2rem'}}>
                                                <DoneIcon style={{color: 'green', marginRight: '10px'}}/>
                                                <p>Verified</p>
                                            </div>
                                    )}
                                        {message && (
                                            <p style={{ textAlign: 'center', marginTop: '1rem', color: 'red' }}>{message}</p>
                                        )} 
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Class</strong></StyledTableCell>
                                    <StyledTableCell>{isEditing ? <input type="text" name="class" value={editedProfile.class} onChange={handleChange} /> : profileData.class}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Date Of Birth</strong></StyledTableCell>
                                    <StyledTableCell>{isEditing ? <input type="date" name="dob" value={editedProfile.dob} onChange={handleChange} /> : profileData.dob}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Father</strong></StyledTableCell>
                                    <StyledTableCell>{isEditing ? <input type="text" name="father" value={editedProfile.father} onChange={handleChange} /> : profileData.father}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Mother</strong></StyledTableCell>
                                    <StyledTableCell>{isEditing ? <input type="text" name="mother" value={editedProfile.mother} onChange={handleChange} /> : profileData.mother}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Gender</strong></StyledTableCell>
                                    <StyledTableCell>{isEditing ? <input type="text" name="gender" value={editedProfile.gender} onChange={handleChange} /> : profileData.gender}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Phone</strong></StyledTableCell>
                                    <StyledTableCell>{isEditing ? <input type="text" name="phone" value={editedProfile.phone} onChange={handleChange} /> : profileData.phone}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>City</strong></StyledTableCell>
                                    <StyledTableCell>{isEditing ? <input type="text" name="city" value={editedProfile.city} onChange={handleChange} /> : profileData.city}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>District</strong></StyledTableCell>
                                    <StyledTableCell>{isEditing ? <input type="text" name="district" value={editedProfile.district} onChange={handleChange} /> : profileData.district}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>State</strong></StyledTableCell>
                                    <StyledTableCell>{isEditing ? <input type="text" name="state" value={editedProfile.state} onChange={handleChange} /> : profileData.state}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Pincode</strong></StyledTableCell>
                                    <StyledTableCell>{isEditing ? <input type="text" name="pincode" value={editedProfile.pincode} onChange={handleChange} /> : profileData.pincode}</StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {isEditing ? (
                            <Button variant="contained" onClick={handleUpdate} style={{width: '2rem', margin: 'auto', backgroundColor: '#ac2cc4'}}>Update</Button>
                    ) : (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '15px'}}>
                            <Button variant="contained" onClick={handleEdit} style={{ backgroundColor: '#ac2cc4'}}>Edit</Button>
                            <Button variant="contained" onClick={handleChangePassword} style={{ backgroundColor: '#ac2cc4'}}>Change Password</Button>
                            <Button variant="contained" onClick={handleLogout} style={{ backgroundColor: '#ac2cc4'}}>LogOut</Button>
                        </div>
                    )}
                       {message && (
                        <p style={{ textAlign: 'center', marginTop: '1rem', color: 'red' }}>{message}</p>
                    )} 
                </div>
            ) : (
                <p>Loading profile data...</p>
            )}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '25px'
                }}
                >
                <h2 style={{textAlign: 'center'}}>Change Password</h2>
                <TextField
                    label="Old Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type={showOldPassword ? 'text' : 'password'}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowOldPassword(!showOldPassword)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    edge="end"
                                >
                                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="New Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type={showNewPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    edge="end"
                                >
                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', gap: '10px'}}>
                    <Button variant="contained" onClick={handleSubmitChangePassword} style={{ backgroundColor: '#ac2cc4'}}>
                    Submit
                    </Button>
                    <Button variant="contained" onClick={handleCloseModal} style={{ backgroundColor: '#ac2cc4'}}>
                    Cancel
                    </Button>
                </div>
                {message && (
                    <p style={{ textAlign: 'center', marginTop: '1rem', color: 'red' }}>{message}</p>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default Profile;