import React, { useState, useEffect } from 'react';
import { TextField, Grid, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function SubjectForm({ onSubjectFormDataChange }) {
  const initialFormData = {
    subject: 'Math',
    subject_marks: '',
    subject_fee: '',
    level: ''
  };

  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState([initialFormData]);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedField, setSelectedField] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  const subjectSuggestions = ['Math', 'Science'];
  const levelSuggestions = ['Level 1', 'Level 2'];

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newData = [...formData];
    newData[index][name] = value;
    setFormData(newData);
  };

  const addRow = () => {
    setFormData([...formData, initialFormData]);
  };

  const deleteRow = (index) => {
    if (formData.length === 1) {
      setDeleteErrorMessage('At least one subject needed');
      return;
    }
    const newData = [...formData];
    newData.splice(index, 1);
    setFormData(newData);
    setDeleteErrorMessage('');
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFieldSelection = (field) => {
    setSelectedField(field);
    setModalOpen(true);
  };


  const handleModalSelection = (value) => {
    let levelValue = value === 'Level 1' ? 1 : 2;
  
    if (selectedField === 'subject') {
      const newData = formData.map((data, index) => ({
        ...data,
        subject: index === formData.length - 1 ? value : data.subject
      }));
      setFormData(newData);
    } else if (selectedField === 'level') {
      const newData = formData.map((data, index) => ({
        ...data,
        level: index === formData.length - 1 ? levelValue : data.level
      }));
      setFormData(newData);
    }
    setSelectedValue(value); 
    setModalOpen(false);
    setSelectedField('');
  };

  useEffect(() => {
    if (selectedField && selectedValue) {
      const newData = [...formData];
      newData[formData.length - 1][selectedField] = selectedValue;
      setFormData(newData);
    }
  }, [selectedField, selectedValue, formData]);

  useEffect(() => {
    onSubjectFormDataChange(formData);
  }, [formData, onSubjectFormDataChange]);

  return (
    <div style={{width: '95%', margin: 'auto'}}>
        <Button
        variant="contained"
        onClick={toggleForm}
        style={{
          width: '100%',
          textAlign: 'center',
          margin: '2rem 0.5rem',
          padding: '1rem 0rem',
          backgroundColor: 'rgb(195 210 237)',
          color: 'black'
        }}
      >
        Subject
      </Button>
      {showForm && (
        <>
          <Grid container spacing={2} style={{ marginLeft: '0' }}>
            {formData.map((data, index) => (
              <Grid item xs={12} key={index}>
                <Grid container spacing={2} alignItems="center">
                  {Object.entries(data).map(([key, value]) => (
                    <Grid item xs={2.7} key={key}>
                      <TextField
                        required
                        fullWidth
                        name={key}
                        label={
                          key === 'level' ? 'Level' :
                            key === 'subject_fee' ? 'Fee' :
                              key === 'subject_marks' ? 'Marks' :
                                key.charAt(0).toUpperCase() + key.slice(1)
                        }
                        type={key === 'subject_marks' || key === 'subject_fee' ? 'number' : 'text'}
                        value={value}
                        onChange={(e) => handleChange(e, index)}
                        onClick={() => {
                          if (key === 'subject' || key === 'level') {
                            handleFieldSelection(key);
                          }
                        }}
                        autoComplete="off"
                      />
                    </Grid>
                  ))}

                  <Grid item xs={1}>
                    <IconButton onClick={() => deleteRow(index)}>
                        <DeleteIcon style={{color: '#FFB606'}}/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
          {deleteErrorMessage && (
            <Typography variant="body2" color="error" align="center">
              {deleteErrorMessage}
            </Typography>
          )}
          <Button variant="contained" onClick={addRow} style={{ marginTop: '1rem', marginLeft: '1rem', backgroundColor: '#80CE66'}}>
            Add Subject
          </Button>
        </>
      )}
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>Select {selectedField === 'subject' ? 'Subject' : 'Level'}</DialogTitle>
        <DialogContent>
          <List>
            {(selectedField === 'subject' ? subjectSuggestions : levelSuggestions).map((suggestion) => (
              <ListItem button key={suggestion} onClick={() => handleModalSelection(suggestion)}>
                <ListItemText primary={suggestion} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubjectForm;
