import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../axiosApi';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableHead, Link, Button, TableContainer, Paper, tableCellClasses, TableCell, TableRow, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NavBar from './NavBar/navBar';
import MenuBookIcon from '@mui/icons-material/MenuBook';

export default function RegisterOlympiadDetails() {
    const [regOlympiad, setRegOlympiad] = useState(null);
    const [olympiadLevel, setOlympiadLevel] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const { id } = useParams(); 

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
  
    useEffect(() => {
        const fetchRegisterOlympiadData = async () => {
            try {
                const token = localStorage.getItem('matrix_token');
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };

                const response = await axios.get(`${backendURL}/student/olympiad/${id}/registered`, {headers});
                setOlympiadLevel(response.data.data)
                setRegOlympiad(response.data.data[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchRegisterOlympiadData();
    }, [id, backendURL]); 

    const handlePayNow = async () => {
        try {
            const token = localStorage.getItem('matrix_token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            const response = await axios.get(`${backendURL}/student/olympiad/${id}/checkout`, {headers});
            const url = decodeURIComponent(response.data.url); 
            window.location.href = url;
        } catch (error) {
            console.error('Error making payment:', error);
        } 
    };  

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <NavBar />
            <div>
                {regOlympiad && (
                    
                    <React.Fragment>
                        <Table style={{ width: '100%', border: '1px solid #ccc', boxShadow: '0 5px 20px 0 rgba(0, 0, 0, .16)' }}>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell colSpan={2} style={{ textAlign: 'center', fontSize: '20px' }}>
                                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
                                            <MenuBookIcon style={{fontSize: '2rem', color: 'white'}}/>
                                            <Typography component="h1" variant="h6" >
                                                Register Olympiad Details
                                            </Typography>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Olympiad Id</strong></StyledTableCell>
                                    <StyledTableCell>{regOlympiad.olympiad_id}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Name</strong></StyledTableCell>
                                    <StyledTableCell>{regOlympiad.participant_olympiad.name}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Description</strong></StyledTableCell>
                                    <StyledTableCell>{regOlympiad.participant_olympiad.description}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Level 1 Start Date</strong></StyledTableCell>
                                    <StyledTableCell>{regOlympiad.participant_olympiad.start_date}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Level 1 End Date</strong></StyledTableCell>
                                    <StyledTableCell>{regOlympiad.participant_olympiad.end_date}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Level 1 Registration Deadline</strong></StyledTableCell>
                                    <StyledTableCell>{regOlympiad.participant_olympiad.registration_deadline}</StyledTableCell>
                                </StyledTableRow>
                                {regOlympiad.participant_olympiad.start_date_2 && (
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Level 2 Start Date</strong></StyledTableCell>
                                    <StyledTableCell>{regOlympiad.participant_olympiad.start_date_2}</StyledTableCell>
                                </StyledTableRow>
                                )}
                                {regOlympiad.participant_olympiad.end_date_2 && (
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Level 2 End Date</strong></StyledTableCell>
                                    <StyledTableCell>{regOlympiad.participant_olympiad.end_date_2}</StyledTableCell>
                                </StyledTableRow>
                                )}
                                 {regOlympiad.participant_olympiad.registration_deadline_2 && (
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Level 2 Registration Deadline</strong></StyledTableCell>
                                    <StyledTableCell>{regOlympiad.participant_olympiad.registration_deadline_2}</StyledTableCell>
                                </StyledTableRow>
                                 )}
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Aadhar Number</strong></StyledTableCell>
                                    <StyledTableCell>{regOlympiad.participant_user.aadhar_number}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell className='tableCell'><strong>Class</strong></StyledTableCell>
                                    <StyledTableCell>{regOlympiad.participant_user.class}</StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                        <TableContainer component={Paper} style={{marginTop: '1rem', width: '100%', border: '1px solid #ccc',  boxShadow: '0 5px 20px 0 rgba(0, 0, 0, .16)'}}>
                            <Table>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell className='tableCell'>Level</StyledTableCell>
                                        <StyledTableCell className='tableCell'>Total Marks</StyledTableCell>
                                        <StyledTableCell className='tableCell'>Obtain Marks</StyledTableCell>
                                        <StyledTableCell className='tableCell'>Hall Ticket Number</StyledTableCell>
                                        <StyledTableCell className='tableCell'>Certificate Url1</StyledTableCell>
                                        <StyledTableCell className='tableCell'>Certificate Url2</StyledTableCell>
                                        <StyledTableCell className='tableCell'>Total Amount</StyledTableCell>
                                        <StyledTableCell className='tableCell'>Payment Status</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {olympiadLevel.map((item, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell className='tableCell'>{item.level}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>{item.total_marks}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>{item.obtain_marks !== null ? item.obtain_marks : "NA" }</StyledTableCell>
                                            <StyledTableCell className='tableCell'>{item.hall_ticket_no !== null ? item.hall_ticket_no : "NA" }</StyledTableCell>
                                            <StyledTableCell className='tableCell'>
                                            {item.certificate_url !== null ? (
                                                    <Link
                                                        component={RouterLink}
                                                        to={item.certificate_url}
                                                        variant="contained"
                                                        target="_blank"
                                                        style={{backgroundColor: '#1b0080', color: 'white', padding: '8px', textDecorationColor: 'white'}}
                                                    >
                                                        Download Certificate
                                                    </Link>
                                                ) : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell className='tableCell'>
                                            {item.certificate_url_2 !== null ? (
                                                    <Link
                                                        component={RouterLink}
                                                        to={item.certificate_url_2}
                                                        variant="contained"
                                                        target="_blank"
                                                        style={{backgroundColor: '#1b0080', color: 'white', padding: '8px', textDecorationColor: 'white'}}
                                                    >
                                                        Download Certificate
                                                    </Link>
                                                ) : "NA"}
                                            </StyledTableCell>
                                            <StyledTableCell className='tableCell'>{item.total_amount}</StyledTableCell>
                                            <StyledTableCell className='tableCell'>
                                                {item.isfullPaid === null ? (
                                                    item.level === 1 ? (
                                                        new Date(item.participant_olympiad.registration_deadline) < new Date() ? (
                                                            "Registration Closed"
                                                        ) : (
                                                            <Button variant="contained" color="primary" onClick={handlePayNow} component={RouterLink} style={{backgroundColor: '#ac2cc4'}}> 
                                                                Pay Now
                                                            </Button>
                                                        )
                                                    ) : item.level === 2 ? (
                                                        new Date(item.participant_olympiad.registration_deadline_2) < new Date() ? (
                                                            "Registration Closed"
                                                        ) : (
                                                            <Button variant="contained" color="primary" onClick={handlePayNow} component={RouterLink} style={{backgroundColor: '#ac2cc4'}}>
                                                                Pay Now
                                                            </Button>
                                                        )
                                                    ) : null
                                                ) : (
                                                    "Paid"
                                                )}
                                            </StyledTableCell>

                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        
                        
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}
