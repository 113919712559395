import React, { useState, useEffect } from 'react';
import axios from '../../axiosApi';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Paper, TablePagination, tableCellClasses, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/School';

const AllSchools = () => {
    const [schoolData, setSchoolData] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const role= localStorage.getItem('matrix_user') ? JSON.parse(localStorage.getItem('matrix_user')).role : null;
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchSchoolData = async () => {
            try {
                const token = localStorage.getItem('matrix_token'); 
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(`${backendURL}/schools`, { headers });
                setSchoolData(response.data);
            } catch (error) {
                console.error('Error fetching school data:', error);
            }
        };

        fetchSchoolData();
    }, [backendURL]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
                <SchoolIcon />
                <h3>School Information</h3>
            </div>
            <TableContainer component={Paper} style={{ maxHeight: 700,  boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)'}}>
                <Table stickyHeader style={{  border: '1px solid #ccc'}}>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell className='tableCell'>ID</StyledTableCell>
                            <StyledTableCell className='tableCell'>Name</StyledTableCell>
                            <StyledTableCell className='tableCell'>Unique Code</StyledTableCell>
                            <StyledTableCell className='tableCell'>Email</StyledTableCell>
                            <StyledTableCell className='tableCell'>Phone</StyledTableCell>
                            <StyledTableCell className='tableCell'>City</StyledTableCell>
                            <StyledTableCell className='tableCell'>District</StyledTableCell>
                            <StyledTableCell className='tableCell'>State</StyledTableCell>
                            <StyledTableCell className='tableCell'>Landmark</StyledTableCell>
                            <StyledTableCell className='tableCell'>Action</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {schoolData && schoolData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((school, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell className='tableCell'>{school.id}</StyledTableCell>
                                <StyledTableCell className='tableCell'>{school.school_name}</StyledTableCell>
                                <StyledTableCell className='tableCell'>{school.school_unique_code}</StyledTableCell>
                                <StyledTableCell className='tableCell'>{school.school_email}</StyledTableCell>
                                <StyledTableCell className='tableCell'>{school.school_phone}</StyledTableCell>
                                <StyledTableCell className='tableCell'>{school.school_city}</StyledTableCell>
                                <StyledTableCell className='tableCell'>{school.school_district}</StyledTableCell>
                                <StyledTableCell className='tableCell'>{school.school_state}</StyledTableCell>
                                <StyledTableCell className='tableCell'>{school.school_landmark}</StyledTableCell>
                                <StyledTableCell className='tableCell'>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        component={RouterLink}
                                            to={
                                                role === 1 ? `/admin/school/${school.id}` :
                                                role === 2 ? `/incharge/school/${school.id}` :
                                                role === 5 ? `/student/school/${school.id}` :
                                                "/404"
                                            }
                                        style={{backgroundColor: '#ac2cc4'}}
                                        >
                                        View
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={schoolData ? schoolData.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default AllSchools;
