import { React } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './Components/Pages/Auth/loginPage';
import Dashboard from './Components/Pages/AuthenticateLayout/Dashboard/UsersDashboard/dashboard';
import RegisterPage from './Components/Pages/Auth/registerPage';
import PrivateRoute from './Components/Pages/Auth/PrivateRoute';
import WelcomePage from './Components/Pages/GuestLayout/welcome';
import UserOlympiads from './Components/Pages/AuthenticateLayout/Dashboard/UsersDashboard/userOlympiads';
import NewOlympiad from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/AdminOlympiad/newOlympiad';
import UserOlympiadId from './Components/Pages/AuthenticateLayout/Dashboard/UsersDashboard/userOlympiadId';
import AdminPrivateRoute from './Components/Pages/Auth/adminPrivateRoute';
import NotFound from './Components/Pages/GuestLayout/notFound';
import RegisterOlympiadDetails from './Components/Pages/AuthenticateLayout/Dashboard/UsersDashboard/registerOlympiadDetails';
import UserProfile from './Components/Pages/AuthenticateLayout/Dashboard/UsersDashboard/UserProfile';
import AdminDashboard from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/adminDashboard';
import AdminAllOlympiads from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/AdminOlympiad/allOlympiads';
import SingleOlympiad from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/AdminOlympiad/singleOlympiad'
import AllSchools from './Components/Pages/AuthenticateLayout/Schools/allSchools';
import AdminAllSchool from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/AdminSchools/adminAllSchool';
import NewSchool from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/AdminSchools/newSchool';
import UsersData from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/Users/Student/usersData';
import InchargeData from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/Users/Incharge/inchargeData';
import PendingInchargeData from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/Users/Incharge/pendingIncharge';
import YourOlympiads from './Components/Pages/AuthenticateLayout/Dashboard/UsersDashboard/yourOlympiads';
 import AdminProfile from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/adminProfile';
import Sucess from './Components/Pages/AuthenticateLayout/Payments/sucess';
import Cancel from './Components/Pages/AuthenticateLayout/Payments/cancel';
import SingleUserData from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/Users/Student/singleUserData';
import SingleInchargeData from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/Users/Incharge/singleInchargeData';
import InchargeOlympiads from './Components/Pages/AuthenticateLayout/Dashboard/Incharge/inchargeOlympiads';
import InchargeSingleRegOlympiad from './Components/Pages/AuthenticateLayout/Dashboard/Incharge/inchargeSingleRegOlympiad';
import SingleSchool from './Components/Pages/AuthenticateLayout/Schools/singleSchool';
import SingleParticipate from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/AdminOlympiad/singleParticipate';
import LogOut from './Components/Pages/AuthenticateLayout/logOut';
import VerifyEmail from './Components/Pages/Auth/verifyEmail';
import StudentRegisterForm from './Components/Pages/Auth/studentRegisterForm';
import InchargeRegisterForm from './Components/Pages/Auth/inchargeRegisterPage';
import NewCenter from './Components/Pages/AuthenticateLayout/Dashboard/AdminDashboard/newCenter';



const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/student/register" element={<StudentRegisterForm />} />
          <Route path="/incharge/register" element={<InchargeRegisterForm />} />
          <Route path="/olympiads" element={<UserOlympiads/>} />
          <Route path="/student/olympiad/your-olympiad" element={<YourOlympiads />} />
          <Route path="/incharge/olympiad/your-olympiad" element={<InchargeOlympiads />} />
          <Route path="/student/olympiads/:id" element={<UserOlympiadId />} />
          <Route path="/incharge/olympiads/:id" element={<UserOlympiadId />} />
          <Route path="/incharge/profile" element={<UserProfile />} />
          <Route path="/student/profile" element={<UserProfile />} />
          <Route path="/student/olympiad/:id/registered" element={<RegisterOlympiadDetails />} />
          <Route path="/incharge/olympiad/:id/registered" element={<InchargeSingleRegOlympiad />} />
          <Route path="/schools" element={<AllSchools />} />
          <Route path="/school/:id" element={<SingleSchool />} />
          <Route path="/sucess/" element={<Sucess />} />
          <Route path="/verify-token" element={<VerifyEmail
           />} />
          <Route path="/cancel/" element={<Cancel />} />
         
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            
          </Route>
          <Route element={<AdminPrivateRoute />}>
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/logout" element={<LogOut />} />
            <Route path="/admin/profile" element={<AdminProfile />} />
            <Route path="/admin/centre/create" element={<NewCenter />} />
            <Route path="/admin/olympiads" element={<AdminAllOlympiads />} />
            <Route path="/admin/olympiad/create" element={<NewOlympiad />} />
            <Route path="/admin/olympiads/:id" element={<SingleOlympiad />} />
            <Route path="/admin/olympiad/:olympiadId/allparticipate/user/:userId/" element={<SingleParticipate />} />
            <Route path="/admin/schools" element={<AdminAllSchool />} />
            <Route path="/admin/school/:id" element={<SingleSchool />} />
            <Route path="/admin/school/create" element={<NewSchool />} />
            <Route path="/admin/school/update/:id" element={<SingleSchool />} />
            <Route path="/admin/users" element={<UsersData />} />
            <Route path="/admin/users/:id" element={<SingleUserData />} />
            <Route path="/admin/incharges" element={<InchargeData />} />
            <Route path="/admin/incharge/:id" element={<SingleInchargeData />} />
            <Route path="/admin/pendingIncharge" element={<PendingInchargeData />} />
          </Route>
          <Route path="*" element={<NotFound />} />
          
        </Routes>
        
      </ThemeProvider>
    </Router>
  );
}

export default App;
