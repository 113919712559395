import React from 'react'
import { Table, TableHead, TableBody, TableCell, TableRow, tableCellClasses, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';


export default function SubjectTable( {olympiad, subjectList, selectedSubjects, handleSubjectSelection } ) {
    const role = localStorage.getItem('matrix_user') ? JSON.parse(localStorage.getItem('matrix_user')).role : null;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

  return (
    <>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
          <AutoStoriesIcon />
          <h3>Subjects</h3>
        </div>
        
    <Table style={{ width: '45%', border: '1px solid #ccc',  marginBottom: '1rem', boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)'}}>
        <TableHead>
        <StyledTableRow>
            <StyledTableCell className='tableCell'></StyledTableCell>
            <StyledTableCell className='tableCell'>Subject</StyledTableCell>
            <StyledTableCell className='tableCell'>Subject Marks</StyledTableCell>
            <StyledTableCell className='tableCell'>Fee</StyledTableCell>
            <StyledTableCell className='tableCell'>Level</StyledTableCell>
        </StyledTableRow>
        </TableHead>
        <TableBody>
        {subjectList.map(subject => (
            <StyledTableRow key={subject.id}>
            <StyledTableCell className='tableCell'>
                <Checkbox
                    disabled={
                        role !== 1 && (
                            role === 2 ||
                            olympiad.allowed === null ||
                            (olympiad.allowed === 1 && subject.level === 2) ||
                            (olympiad.allowed === 2 && subject.level === 1)
                        )
                    }
                checked={selectedSubjects.includes(subject.id)}
                onChange={() => handleSubjectSelection(subject.id)}
                
                />
            </StyledTableCell>
            <StyledTableCell className='tableCell'>{subject.subject}</StyledTableCell>
            <StyledTableCell className='tableCell'>{subject.subject_marks}</StyledTableCell>
            <StyledTableCell className='tableCell'>{subject.subject_fee}</StyledTableCell>
            <StyledTableCell className='tableCell'>{subject.level}</StyledTableCell>
            </StyledTableRow>
        ))}
        </TableBody>
    </Table>
    </>
    
  )
}
