import React from 'react';
import { handleLogout } from './Dashboard/AdminDashboard/sidenav/accountButton'; 

export default function LogOut() {
  const handleLogoutClick = async () => {
    await handleLogout();
  };

  return (
    <div>
      <button onClick={handleLogoutClick}>Log Out</button>
    </div>
  );
}
