import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Container, CssBaseline, Typography, Paper, CircularProgress } from '@mui/material';
import axios from '../../../../axiosApi';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);


export default function SchoolGraph() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('matrix_token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            try {
                const response = await axios.get(`${backendURL}/admin/chart/2`, { headers });
                const fetchedData = response.data;

                const chartData = {
                    labels: fetchedData.map(item => item.school_name),
                    datasets: [
                        {
                            label: 'Student Count',
                            data: fetchedData.map(item => item.students_count),
                            // data: [1000, 2000, 3000, 4000],
                            fill: false,
                            borderColor: '#ab47bc',
                            tension: 0.1
                        },
                    ]
                };

                setData(chartData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [backendURL]);

    const options = {
        responsive: true,
            plugins: {
                legend: {
                position: "bottom",
                },
                title: {
                display: false,
                text: "School Data",
                font: {
                    size: 18,
                    weight: "bold",
                },
                },
            },
            scales: {
                x: {
                title: {
                    display: true,
                    text: "School Name",
                    font: {
                    size: 14,
                    weight: "bold",
                    },
                },
                grid: {
                    display: false,
                },
                },
                y: {
                title: {
                    display: true,
                    text: "Students Count",
                    font: {
                    size: 14,
                    weight: "bold",
                    },
                },
                grid: {
                    color: "rgba(0, 0, 0, 0.1)",
                },
                },
            },
            elements: {
                point: {
                radius: 3,
                backgroundColor: "#ab47bc",     
                },
                line: {
                borderWidth: 2,
                borderColor: "#ab47bc",
                tension: 0.3,
                fill: false,
                },
            },
    };

  return (
    <div>
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    School Data
                </Typography>
                <Paper elevation={3} sx={{ padding: 2, marginTop: 3, width: '100%', position: 'relative' }}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Line data={data} options={options} />
                    )}
                </Paper>
            </Box>
        </Container>
</div>
  )
}
