import React from 'react';
import {Outlet, Navigate } from 'react-router-dom';

const PrivateRoute = () => {
  const isLogin = localStorage.getItem('matrix_token');
  const isAuthenticated = isLogin && isLogin !== ''; 
 
  return (
    isAuthenticated ? <Outlet/> : <Navigate to="/login" />
        
  );
}

export default PrivateRoute;