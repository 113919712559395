import React, { useState, useEffect } from 'react';
import { Button, CssBaseline, TextField, Grid, Box, Container, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from '../../../../axiosApi';
import { Navigate } from 'react-router-dom';
import Sidenav from '../sidenav/sidenav';
import SchoolIcon from '@mui/icons-material/School';

const defaultTheme = createTheme();

const NewSchool = () => {
  const initialFormData = {
    school_name: '',
    school_email: '',
    school_phone: '',
    school_city: '',
    school_district: '',
    school_state: '',
    school_landmark: '',
    school_unique_code: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [matrixToken, setMatrixToken] = useState(null);
  const [requiredFieldsError, setRequiredFieldsError] = useState({
    school_name: '',
    school_email: '',
    school_phone: '',
    school_city: '',
    school_district: '',
    school_state: '',
    school_landmark: '',
    school_unique_code: ''
  });
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const token = localStorage.getItem('matrix_token');
    setMatrixToken(token);
  }, []); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    // Clear error message when user starts typing
    setRequiredFieldsError({
      ...requiredFieldsError,
      [name]: ''
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate required fields
    let hasError = false;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newRequiredFieldsError = {};
    if (formData['school_email'] && !emailRegex.test(formData['school_email'])) {
      newRequiredFieldsError['school_email'] = 'Invalid email format.';
      hasError = true;
    }
  
    const newErrors = {};
    for (const key in formData) {
      if (!formData[key]) {
        newErrors[key] = `${key.replace('_', ' ')} is required`;
        hasError = true;
      }
    }
    setRequiredFieldsError({
      ...newErrors,
      ...newRequiredFieldsError // Add error for invalid email format
    });
  
    if (!hasError) {
      try {
        const headers = {
          'Authorization': `Bearer ${matrixToken}`,
          'Content-Type': 'application/json'
        };
        console.log(formData, "formData");
        const response = await axios.post(`${backendURL}/admin/school/create`, formData, { headers });
        console.log('Form submitted:', response.data);
        setRedirectToReferrer(true);
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };
  

  if (redirectToReferrer) {
    return <Navigate to="/admin/schools" />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box sx={{ display: 'flex', marginTop: 0 }}>
          <Sidenav />
          <Box sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1,
            backgroundColor: 'white',
            boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)',
            padding: '0rem 2rem',
            margin: '2rem 0rem',
            borderRadius: '25px'
          }}>
            {formData.error && <div>{formData.error}</div>}
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                    <SchoolIcon  style={{fontSize: '2rem', color: '#ac2cc4'}}/>
                    <Typography variant="h5" align="center" style={{fontWeight: '600'}}>School Creation Form</Typography>
                  </div>
                </Grid>
                
                {Object.entries(formData).map(([key, value]) => (
                  <Grid item xs={12} key={key}>
                    <TextField
                      required
                      fullWidth
                      name={key}
                      label={key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')}
                      type={key === 'school_phone' ? 'number' : 'text' }
                      value={value}
                      onChange={handleChange}
                      error={!!requiredFieldsError[key]}
                      helperText={requiredFieldsError[key]}
                      inputProps={{
                        maxLength: key === 'school_phone' ? 10 : undefined,
                        onInput: (e) => {
                          if (key === 'school_phone') {
                            e.target.value = e.target.value.slice(0, 10);
                          }
                        }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  type="submit"
                  style={{backgroundColor: '#ac2cc4', width: '7rem'}}
                >
                  Create
                </Button>
              </div>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default NewSchool;




