import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import axios from '../../../../axiosApi';

export const handleLogout = async () => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    console.log('hello');
    try {
        const token = localStorage.getItem('matrix_token');
        if (token) {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
            await axios.post(`${backendURL}/logout`, {}, { headers });
            localStorage.removeItem('matrix_token');
            localStorage.removeItem('matrix_user');
            window.location.href = '/';
        } else {
            console.error('No token found in localStorage.');
        }
    } catch (error) {
        console.error('Error logging out:', error);
    }
};


export default function AccountButton() {
    const role = localStorage.getItem('matrix_user') ? JSON.parse(localStorage.getItem('matrix_user')).role : null;
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  return (
        <div>
            <Button
                id="dropdown-button"
                aria-controls="dropdown-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
            >
                Account
            </Button>
            <Menu
                id="dropdown-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {role === 1 && (
                    <MenuItem onClick={handleClose} component={Link} to={'/admin/profile'}>
                        Profile
                    </MenuItem>
                )}
                {role === 2 && (
                    <MenuItem onClick={handleClose} component={Link} to={'/incharge/profile'}>
                        Profile
                    </MenuItem>
                )}
                {role === 5 && (
                    <MenuItem onClick={handleClose} component={Link} to={'/student/profile'}>
                        Profile
                    </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                    Log Out
                </MenuItem>
            </Menu>
        </div>
  );
}
