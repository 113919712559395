import React, { useState, useEffect } from 'react';
import { Button, CssBaseline, TextField, Link, Grid, Box, MenuItem, Container, Typography, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Navigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from '../axiosApi';
import NavBar from '../AuthenticateLayout/Dashboard/UsersDashboard/NavBar/navBar';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import StudentRegAvatar from '../../../assets/studentRegAvatar.jpg'


const defaultTheme = createTheme();

export default function StudentRegisterForm() {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const initialFormData = {
    name: '',
    email: '',
    aadhar_number: '',
    phone: '',
    father: '',
    mother: '',
    class: '',
    dob: '',
    city: '',
    district: '',
    state: 'Andhra Pradesh',
    pincode: '',
    school_id: '',
    password: ''
    
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [registerError, setRegisterError] = useState('');
  const [requiredFieldsError, setRequiredFieldsError] = useState({
    name: '',
    email: '',
    aadhar_number: '',
    phone: '',
    father: '',
    mother: '',
    class: '',
    dob: '',
    city: '',
    district: '',
    pincode: '',
    school_id: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setRequiredFieldsError({
      ...requiredFieldsError,
      [name]: '',
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSchoolChange = (e) => {
    const { value } = e.target;
    const selectedSchool = options.find(option => option.id === value);
    setFormData({
      ...formData,
      school_id: selectedSchool.id
    });
    setRequiredFieldsError({
      ...requiredFieldsError,
      school_id: '',
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendURL}/school`);
        setOptions(response.data);
      } catch (error) {
        console.error('Error fetching options:', error);
        if (error.response && error.response.status === 429) {
          const delay = Math.pow(2, 3) * 1000; 
          console.log(`Rate limited. Retrying in ${delay / 1000} seconds...`);
          await new Promise(resolve => setTimeout(resolve, delay));
          fetchData(); 
        }
      }
    };
  
    fetchData(); 
  }, [backendURL]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const requiredFields = ['name', 'email', 'aadhar_number', 'phone', 'father', 'mother', 'class', 'dob', 'city', 'district', 'pincode', 'school_id', 'password'];
    const newRequiredFieldsError = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newRequiredFieldsError[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
      }
    });

      if (formData['email'] && !emailRegex.test(formData['email'])) {
        newRequiredFieldsError['email'] = 'Invalid email format.';
      }

      if (Object.keys(newRequiredFieldsError).length > 0) {
        setRequiredFieldsError(newRequiredFieldsError);
        setLoading(false); 
        return;
      }
    const formDataWithRegisterStudent = { ...formData, register_as_student: true };
    try {
      const response = await axios.post(`${backendURL}/register`, formDataWithRegisterStudent);
      // console.log('Registration successful:', response.data);
      const matrix_user = {
        id: response.data.user.id,
        name: response.data.user.name,
        email: response.data.user.email,
        role: response.data.user.role,
      };
      localStorage.setItem('matrix_user', JSON.stringify(matrix_user));
      localStorage.setItem('matrix_token', response.data.token);
      const token = localStorage.getItem('matrix_token');
      console.log(token ,'token');
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
      const emailVerificationResponse = await axios.get(`${backendURL}/verify-email`, { headers }, {
        params: {
          email: formData['email']
        }
      });
      console.log('Email verification initiated:', emailVerificationResponse.data);
      setMessage("Verification mail sent to Email")
      setRedirectToReferrer(true);
    } catch (error) {
      console.error('Registration failed:', error);
      setRegisterError('Registration failed. Please try again.');
    } finally {
      setLoading(false); 
    }
  };
  
  if (redirectToReferrer) {
    return <Navigate to="/dashboard" />;
  }
  

  return (
    <>
      <NavBar />
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 28,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            backgroundColor: 'white',
            boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)',
            padding: '1.5rem',
            borderRadius: '25px'
          }}
        >
          
            <Box sx={{ width: { xs: '100%', md: '48%' }, mb: { xs: 3, md: 0 } }}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                <AddReactionIcon  style={{fontSize: '2rem', color: '#ac2cc4'}}/>
                <Typography component="h1" variant="h5">
                  Student Registration Form
                </Typography>
              </div>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  {Object.entries(formData).map(([key, value]) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={key}>
                      {key === 'class' ? (
                        <React.Fragment>
                          <div style={{marginBottom: '1rem'}}>
                          <TextField
                            required
                            fullWidth
                            select
                            name={key}
                            label={key.charAt(0).toUpperCase() + key.slice(1)}
                            value={value.replace(/\D/g, '')}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: {value} ? true : false,
                            }}
                            
                          >
                            {['3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th'].map((option) => (
                              <MenuItem key={option} value={option.replace(/\D/g, '')}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                          {requiredFieldsError[key] && <Typography variant="body2" color="error">{requiredFieldsError[key]}</Typography>}
                          </div>
                        </React.Fragment>
                      ) : key === 'school_id' ? (
                        <React.Fragment>
                          <TextField
                            required
                            fullWidth
                            select
                            name={key}
                            label= "School"
                            value={value}
                            onChange={handleSchoolChange}
                            InputLabelProps={{
                              shrink: {value} ? true : false,
                            }}
                          >
                            {options.map(option => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.school_name}
                              </MenuItem>
                            ))}
                          </TextField>
                          {requiredFieldsError[key] && <Typography variant="body2" color="error">{requiredFieldsError[key]}</Typography>}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <TextField
                            required
                            fullWidth
                            name={key}
                            label={key.charAt(0).toUpperCase() + key.slice(1)}
                            type={
                              key === 'password'
                                ? showPassword
                                  ? 'text'
                                  : 'password'
                                : key === 'phone' || key === 'pincode' || key === 'aadhar_number'
                                ? 'number'
                                : key === 'dob'
                                ? 'date'
                                : 'text'
                            }
                            value={value}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: value ? true : key === 'dob' ? true : false,
                            }}
                            inputProps={{
                              maxLength: key === 'phone' ? 10 : key === 'aadhar_number' ? 12 : key === 'pincode' ? 8 : undefined,
                              onInput: (e) => {
                                if (key === 'phone') {
                                  e.target.value = e.target.value.slice(0, 10);
                                }
                                if (key === 'aadhar_number') {
                                  e.target.value = e.target.value.slice(0, 12);
                                }
                              }
                            }}
                            disabled={key === 'state'}
                            InputProps={
                              key === 'password'
                                ? {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          edge="end"
                                        >
                                          {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }
                                : null
                            }
                          />
                          {requiredFieldsError[key] && <Typography variant="body2" color="error">{requiredFieldsError[key]}</Typography>}
                        </React.Fragment>
                      )}
                    </Grid>
                  ))}
                </Grid>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading} 
                  style={{backgroundColor: '#ac2cc4'}}
                >
                  Sign Up
                </Button>
                {registerError && <Typography variant="body2" color="error">{registerError}</Typography>}
                {message && <p style={{color: 'red'}}>{message}</p>}
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link
                        component={RouterLink}
                        variant="contained"
                        color="primary"
                        to='/login'
                    >
                        Already have an account? Login
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={{ width: { xs: '100%', md: '48%' }, textAlign: 'center' }}>
                <img src={StudentRegAvatar} alt="incharge-registration-avatar" style={{ width: '100%', height: 'auto' }} />
            </Box>
          
        </Box>
      </Container>
    </ThemeProvider>
    </>
    
  );
}
