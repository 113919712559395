import React, { useState } from 'react';
import {  Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';
import { handleLogout } from '../../AdminDashboard/sidenav/accountButton';

const OlympiadButton = () => {
    // const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const token = localStorage.getItem('matrix_token');
    const role = localStorage.getItem('matrix_user') ? JSON.parse(localStorage.getItem('matrix_user')).role : null;

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        handleCloseMenu();
        if(option === 'Login'){
            setTimeout(() => {
                window.location.href = '/login';
            }, 0);
        }
        
    };
    
    return (
        <>
            <div style={{ backgroundColor: '#ac2cc4', cursor: 'pointer', padding: '10px', borderRadius: '4px', display: 'inline-flex', alignItems: 'center', color: 'white' }} onClick={handleOpenMenu}>
                {selectedOption ? selectedOption : 'Olympiads'}
                <ArrowDropDownIcon />
            </div>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {token === null ? (
                    [
                        <MenuItem key="login" onClick={() => handleOptionSelect('Login')} style={{ textDecoration: 'none', color: 'inherit' }}>
                            LogIn
                        </MenuItem>,
                        <MenuItem key="studentRegistration" onClick={() => handleOptionSelect('Student Registration')}>
                            <Link to={'/student/register'} style={{ textDecoration: 'none', color: 'inherit' }}>Student Registration</Link>
                        </MenuItem>,
                        <MenuItem key="staffRegistration" onClick={() => handleOptionSelect('Staff Registration')}>
                            <Link to={'/incharge/register'} style={{ textDecoration: 'none', color: 'inherit' }}>Staff Registration</Link>
                        </MenuItem>
                    ]
                ) : (
                    [
                        <MenuItem key="profile" onClick={() => handleOptionSelect('Profile')}>
                            <Link to={role === 5 ? '/student/profile' : '/incharge/profile'} style={{ textDecoration: 'none', color: 'inherit' }}>Profile</Link>
                        </MenuItem>,
                        <MenuItem key="yourOlympiads" onClick={() => handleOptionSelect('Your Olympiads')}>
                            <Link to={role === 5 ? '/student/olympiad/your-olympiad' : '/incharge/olympiad/your-olympiad'} style={{ textDecoration: 'none', color: 'inherit' }}>Your Olympiads</Link>
                        </MenuItem>,
                        <MenuItem key="logout" onClick={handleLogout}>
                            Log Out
                        </MenuItem>
                    ]
                )}
            </Menu>
        </>
    );
};

export default OlympiadButton;
