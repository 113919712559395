import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MuiDrawer from "@mui/material/Drawer";
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuList from "./menulist";
import { Link } from 'react-router-dom';

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidenav() {
  const [open, setOpen] = useState(false);
  const isSmallerScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isLargerScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const wordPressURL = process.env.REACT_APP_WORDPRESS_URL;

  useEffect(() => {
    if (isSmallerScreen) {
      setOpen(false); 
    } else if (isLargerScreen) {
      setOpen(true); 
    }
  }, [isSmallerScreen, isLargerScreen]);

  const handleDrawerState = () => {
    setOpen(!open);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerState} style={{ margin: '0px 10px' }}> 
          <MenuIcon />
        </IconButton>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '9rem', marginLeft: '1rem'}}>
          <Typography variant="h6" sx={{ color: '#ab47bc', marginBottom: '-0.35em' }} noWrap>
            <Link to={wordPressURL}>
              <img src='https://matrixmath.phantasm.solutions/wp-content/uploads/2024/04/MAtrix_WEBSITE-25.png' alt='logo' style={{ width: '200px', margin: '1rem 0px'}} />
            </Link>
          </Typography>
        </div>
      </DrawerHeader>
      <Divider/>
      <MenuList open={open} />
      
      
    </Drawer>
  );
}