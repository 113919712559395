import React, { useState, useEffect } from 'react';
import axios from '../../axiosApi';
import { Table, TableContainer, TableHead, TableBody, TableCell, TableRow, Typography, Container, Pagination, Button,  tableCellClasses, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import './olympiad.css';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Olympiad from '../../../../assets/olympiad.png'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const Olympiads = () => {
  const [data, setData] = useState([]);
  const role= localStorage.getItem('matrix_user') ? JSON.parse(localStorage.getItem('matrix_user')).role : null;
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendURL}/olympiads?page=${currentPage}`);
        setData(response.data.data); 
        setPagination(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [backendURL, currentPage]);

  

 

  const truncateDescription = (description) => {
    return description.length > 30 ? `${description.slice(0, 30)}...` : description;
  };

  return (
    <>
    <Container style={{marginTop: '1rem'}}>
      
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', marginBottom: '1rem'}}>
            
            <img src={Olympiad} alt='Olympiad' style={{height: '45px', width: '45px'}}/>
              <Typography component="h1" variant="h4" >
                Olympiads
              </Typography>
        </div>
      {role === 1 && (
        <Button component={RouterLink} to="/admin/dashboard" variant="h6" color="primary" style={{backgroundColor: '#ac2cc4', color: 'white', marginBottom: '1rem'}}>
          Admin Dashboard
        </Button>
      )}
      <TableContainer style={{ overflowX: 'auto', boxShadow: '0 5px 20px 0 rgba(0, 0, 0, .16)' }}>
        <Table className='table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell className='tableCell'>Id</StyledTableCell>
              <StyledTableCell className='tableCell'>Name</StyledTableCell>
              <StyledTableCell className='tableCell'>Description</StyledTableCell>
              <StyledTableCell className='tableCell'>Start Date</StyledTableCell>
              <StyledTableCell className='tableCell'>End Date</StyledTableCell>
              <StyledTableCell className='tableCell'>Registration Deadline</StyledTableCell>
              <StyledTableCell className='tableCell'>Action</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <StyledTableRow key={index}>
                  <StyledTableCell className='tableCell'>{item.id}</StyledTableCell>
                  <StyledTableCell className='tableCell'>
                  <Link
                      to={
                        role === 1 ? `/admin/olympiads/${item.id}` :
                        role === 2 ? `/incharge/olympiads/${item.id}` :
                        role === 5 ? `/student/olympiads/${item.id}` :
                        "/404"
                      }
                      component={RouterLink}
                      variant="contained"
                      color="primary"
                  
                  >
                      {item.name}
                  </Link>
                  </StyledTableCell>
                  <StyledTableCell className='tableCell'>
                    <Tooltip title={item.description}>
                    <span>{truncateDescription(item.description)}</span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell className='tableCell'>{item.start_date}</StyledTableCell>
                  <StyledTableCell className='tableCell'>{item.end_date}</StyledTableCell>
                  <StyledTableCell className='tableCell'>{item.registration_deadline}</StyledTableCell>
                  <StyledTableCell className='tableCell'>
                  <Button
                    component={RouterLink}
                    to={
                      role === 1 ? `/admin/olympiads/${item.id}` :
                      role === 2 ? `/incharge/olympiads/${item.id}` :
                      role === 5 ? `/student/olympiads/${item.id}` :
                      "/404"
                    }
                    variant="contained"
                    color="primary"
                    style={{backgroundColor: '#ac2cc4'}}
                  >
                    View
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            <Pagination
              count={pagination.last_page}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              variant="outlined"
              shape="rounded"
            />
          </div>
        )}
      
    </Container>
    </>
    
    
  );
};

export default Olympiads;
