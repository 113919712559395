import React, { useState, useEffect } from 'react';
import axios from '../../../../../axiosApi';
import { Table, TableBody, TableCell, TableHead, TableRow, Container, Box, styled, tableCellClasses } from '@mui/material';
import { useParams } from 'react-router-dom';
import Sidenav from '../../sidenav/sidenav'; 

export default function SingleUserData() {
    const [singleUserData, setSingleUserData] = useState([]);
    const [singleUserSchool, setSingleUserSchool] = useState([]);
    const { id } = useParams(); 
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

    useEffect(() => {
        const fetchSingleUserData = async () => {
            try {
                const token = localStorage.getItem('matrix_token'); 
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(`${backendURL}/admin/users/${id}`, { headers });
                setSingleUserData(response.data.data);
                setSingleUserSchool(response.data.data.user_school);

            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchSingleUserData();
    }, [id, backendURL]);
  return (
    <Box sx={{ display: 'flex' }}>
        <Sidenav />
        <Container sx={{ flexGrow: 1, ml: 2}}>
            <Box sx={{  display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4rem' }}>
                {singleUserData && (
                    <Table style={{ width: '45%', border: '1px solid #ccc', boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)' }}>
                    <TableHead>
                        <StyledTableRow>
                        <StyledTableCell colSpan={2} style={{ textAlign: 'center' }}>Student Details</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Id</strong></StyledTableCell>
                        <StyledTableCell>{singleUserData.id}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Name</strong></StyledTableCell>
                        <StyledTableCell>{singleUserData.name}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Email</strong></StyledTableCell>
                        <StyledTableCell>{singleUserData.email}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Phone</strong></StyledTableCell>
                        <StyledTableCell>{singleUserData.phone}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>Aadhar Number</strong></StyledTableCell>
                        <StyledTableCell>{singleUserData.aadhar_number}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Name</strong></StyledTableCell>
                        <StyledTableCell>{singleUserSchool.school_name}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Email</strong></StyledTableCell>
                        <StyledTableCell>{singleUserSchool.school_email}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Phone</strong></StyledTableCell>
                        <StyledTableCell>{singleUserSchool.school_phone}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                        <StyledTableCell className='tableCell'><strong>School Unique Code</strong></StyledTableCell>
                        <StyledTableCell>{singleUserSchool.school_unique_code}</StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                    </Table>
                )}
            </Box>
        </Container>
    </Box>
    
  )
}
