import React, { useState } from 'react';
import { Grid } from '@mui/material';
import StudentRegisterForm from './studentRegisterForm';
import InchargeRegisterForm from './inchargeRegisterPage';
import NavBar from '../AuthenticateLayout/Dashboard/UsersDashboard/NavBar/navBar';

const RegisterPage = () => {
    const [showInchargeForm, setShowInchargeForm] = useState(false);

    const toggleInchargeForm = () => {
        setShowInchargeForm(!showInchargeForm);
    };

    return (
        <>
            <NavBar />
            <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item style={{marginBottom: '0px !important'}}>
                    <label>
                        <input
                            type="checkbox"
                            checked={showInchargeForm}
                            onChange={toggleInchargeForm}
                        />
                        Register as Incharge
                    </label>
                </Grid>
                <Grid item>
                    {showInchargeForm ? <InchargeRegisterForm /> : <StudentRegisterForm />}
                </Grid>
            </Grid>
        </>
        
    );
};

export default RegisterPage;
