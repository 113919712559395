import React from 'react';
import { Container, Box } from '@mui/material';
import Sidenav from '../sidenav/sidenav';
import AllSchools from '../../../Schools/allSchools';

const AdminAllSchool = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Sidenav />
            <Container sx={{ flexGrow: 1, ml: 2 }}>
                <Box sx={{ mt: 4 }}>
                    <AllSchools />
                </Box>
            </Container>
        </Box>
    );
};

export default AdminAllSchool;
