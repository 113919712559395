import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Box, Button, Autocomplete, Grid, Typography } from '@mui/material';
import SubjectTable from '../../../Olympiads/subjectTable';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import axios from '../../../../axiosApi';

export default function ManualRegister({ selectedLevel, id }) {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [aadharNumber, setAadharNumber] = useState('');
    const [aadharOptions, setAadharOptions] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [olympiad, setOlympiad] = useState(null);
    const [options, setOptions] = useState([]);
    const [message, setMessage] = useState('');
    const initialFormData = {
        name: '',
        email: '',
        phone: '',
        father: '',
        mother: '',
        class: '',
        dob: '',
        city: '',
        district: '',
        state: 'Andhra Pradesh',
        pincode: '',
        school_id: '',
        
    };
    const [requiredFieldsError, setRequiredFieldsError] = useState({
        name: '',
        email: '',
        aadhar_number: '',
        phone: '',
        father: '',
        mother: '',
        class: '',
        dob: '',
        city: '',
        district: '',
        pincode: '',
        school_id: '',
      });
      const [formData, setFormData] = React.useState(initialFormData);

      useEffect(() => {
        // Fetch olympiad data
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('matrix_token');
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };

                const response = await axios.get(`${backendURL}/olympiads/${id}`, { headers });
                setOlympiad(response.data);
                setSubjectList(response.data.data.subjects);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id, backendURL]);

    // Function to handle subject selection
    const handleSubjectSelection = (subjectId) => {
        setSelectedSubjects((prevSelected) => {
          if (prevSelected.includes(subjectId)) {
            return prevSelected.filter((id) => id !== subjectId);
          } else {
            return [...prevSelected, subjectId];
          }
        });
      };

      useEffect(() => {
        // Fetch Aadhar options
        const fetchAadharOptions = async () => {
            try {
                const token = localStorage.getItem('matrix_token');
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(`${backendURL}/admin/student/aadhar`, {headers}); 
                setAadharOptions(response.data.data);
            } catch (error) {
                console.error('Error fetching Aadhar options:', error);
            }
        };

        fetchAadharOptions();
    }, [backendURL]);

    const handleAadharChange = async (event, newValue) => {
        if (newValue) {
            try {
                const token = localStorage.getItem('matrix_token');
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await axios.get(`${backendURL}/admin/aadhar/${newValue}`, { headers });
                const responseData = response.data.data;
    
                // Update the form data state with the received data
                setFormData({
                    ...formData,
                    name: responseData.name,
                    email: responseData.email,
                    phone: responseData.phone,
                    father: responseData.father,
                    mother: responseData.mother,
                    class: responseData.class,
                    dob: responseData.dob,
                    city: responseData.city,
                    district: responseData.district,
                    pincode: responseData.pincode,
                    school_id: responseData.school_id,
                    
                });
                setAadharNumber(newValue)
            } catch (error) {
                console.error('Error fetching data based on Aadhar:', error);
            }
        }
    };
    const selectedSubjectData = subjectList.filter((subject) =>
        selectedSubjects.includes(subject.id)
    );
    const selectedSubjectIds = selectedSubjectData.map(subject => subject.id);
    const selectedSubjectLevel = selectedSubjectData.map(subject => subject.level)[0];
    
    const olympiad_data = {
        subjects: selectedSubjectIds,
        level: selectedSubjectLevel
    };
    const requestData1 = {
        name: formData.name,
        email: formData.email,
        aadhar_number: aadharNumber,
        phone: formData.phone,
        father: formData.father,
        mother: formData.mother,
        class: formData.class,
        dob: formData.dob,
        city: formData.city,
        district: formData.district,
        pincode: formData.pincode,
        school_id: formData.school_id,
        olympiad_id: id,
        subjects: olympiad_data.subjects,
        level: olympiad_data.level
      };
   
      const requestData2 = {
        aadhar_number: aadharNumber,
        level: olympiad_data.level,
        olympiad_id: id,
        subjects: olympiad_data.subjects,
        school_id: formData.school_id
    }

    const handleSubmit1 = async (event) => {
        
        event.preventDefault();
       
        const requiredFields = ['name', 'email', 'phone', 'father', 'mother', 'class', 'dob', 'city', 'district', 'pincode', 'school_id'];
        
        const newRequiredFieldsError = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        requiredFields.forEach(field => {
        if (!formData[field]) {
            newRequiredFieldsError[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
        }
        });

        if (formData['email'] && !emailRegex.test(formData['email'])) {
            newRequiredFieldsError['email'] = 'Invalid email format.';
        }

        if (Object.keys(newRequiredFieldsError).length > 0) {
            setRequiredFieldsError(newRequiredFieldsError); 
            return;
        } 
         try {
           
    
            const token = localStorage.getItem('matrix_token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
    
            const response = await axios.post(`${backendURL}/admin/manualRegister1`, requestData1, { headers });
            
            setAadharNumber('');
            setMessage(response.data.message);
         } catch (error) {
             console.error('Error:', error);
             setMessage('Error in uploading data');
         }
    };
    const handleSubmit2 = async (event) => {
        
        event.preventDefault();
        
        const requiredFields = ['name', 'email', 'phone', 'father', 'mother', 'class', 'dob', 'city', 'district', 'pincode', 'school_id'];
        
        const newRequiredFieldsError = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        requiredFields.forEach(field => {
        if (!formData[field]) {
            newRequiredFieldsError[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
        }
        });

        if (formData['email'] && !emailRegex.test(formData['email'])) {
            newRequiredFieldsError['email'] = 'Invalid email format.';
        }

        if (Object.keys(newRequiredFieldsError).length > 0) {
            setRequiredFieldsError(newRequiredFieldsError);
            return;
        } 
         try { 
    
            const token = localStorage.getItem('matrix_token');
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };
    
            const response = await axios.post(`${backendURL}/admin/manualRegister2`, requestData2, { headers });
            
            setAadharNumber('');
            setMessage(response.data.message);
            setRequiredFieldsError({});
         } catch (error) {
             console.error('Error:', error);
             setMessage('Error in uploading data');
         }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
        setRequiredFieldsError({
          ...requiredFieldsError,
          [name]: '',
        });
    };

      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${backendURL}/school`);
            setOptions(response.data);
          } catch (error) {
            if (error.response && error.response.status === 429) {
              const delay = Math.pow(2, 3) * 1000; 
              await new Promise(resolve => setTimeout(resolve, delay));
              fetchData(); 
            }
          }
        };
      
        fetchData(); 
      }, [backendURL]);
    const handleSchoolChange = (e) => {
        const { value } = e.target;
        const selectedSchool = options.find(option => option.id === value);
        setFormData({
          ...formData,
          school_id: selectedSchool.id
        });
        setRequiredFieldsError({
          ...requiredFieldsError,
          school_id: '',
        });
      };

  return (
    <div style={{  width: '40rem', boxShadow: '0 3px 15px 0 rgba(0, 0, 0, .16)', borderRadius: '25px', padding: '2rem', margin: '1rem 3rem'}}>
            
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
                    <HowToRegIcon />
                    <h3>Manual Registration</h3>
                </div>
                <form onSubmit={selectedLevel === 1 ? handleSubmit1 : handleSubmit2}>
                    <Box sx={{ marginTop: '2rem', display: 'flex', flexDirection: 'column'}}>
                        <Autocomplete
                            options={aadharOptions}
                            getOptionLabel={(option) => option ? option.toString() : ''}
                            renderOption={(props, option) => (
                                <li {...props} key={option}>
                                    {option}
                                </li>
                            )}
                        
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    type='number'
                                    label="Aadhar Number"
                                    variant="outlined"
                                    value={aadharNumber}
                                    onChange={(e) => {
                                        let inputValue = e.target.value.slice(0, 12); 
                                        inputValue = inputValue.replace(/^0+/, '');
                                        setAadharNumber(inputValue);
                                    }}
                                />
                            )}
                            onChange={handleAadharChange}
                            freeSolo
                            sx={{ width: '15rem', marginBottom: '1rem' }}
                        />
                        <Grid container spacing={2}>
                        {Object.entries(formData).map(([key, value]) => (
                        <Grid item xs={12} md={6} key={key}>
                            {key === 'class' ? (
                                <React.Fragment>
                                <TextField
                                    required
                                    select
                                    name={key}
                                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                                    value={value.toString().replace(/\D/g, '')}
                                    onChange={handleChange}
                                    style={{width: '15rem'}}
                                    
                                >
                                    {['3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th'].map((option) => (
                                    <MenuItem key={option} value={option.replace(/\D/g, '')}>
                                        {option}
                                    </MenuItem>
                                    ))}
                                </TextField>
                                {requiredFieldsError[key] && <Typography variant="body2" color="error">{requiredFieldsError[key]}</Typography>}
                                </React.Fragment>
                            ) : key === 'school_id' ? (
                                <React.Fragment>
                                <TextField
                                    required
                                    style={{width: '15rem'}}
                                    select
                                    name={key}
                                    label= "School"
                                    value={value}
                                    onChange={handleSchoolChange}
                                >
                                    {options.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.school_name}
                                    </MenuItem>
                                    ))}
                                </TextField>
                                {requiredFieldsError[key] && <Typography variant="body2" color="error">{requiredFieldsError[key]}</Typography>}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                <TextField
                                    required
                                style={{width: '15rem'}}
                                    name={key}
                                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                                    type={
                                    key === 'phone' || key === 'pincode'
                                        ? 'number'
                                        : key === 'dob'
                                        ? 'date'
                                        : 'text'
                                    }
                                    value={value}
                                    onChange={handleChange}
                                    InputLabelProps={key === 'dob' ? { shrink: true } : {}}
                                    inputProps={{
                                    maxLength: key === 'phone' ? 10  : key === 'pincode' ? 8 : undefined,
                                    onInput: (e) => {
                                        if (key === 'phone') {
                                        e.target.value = e.target.value.slice(0, 10);
                                        }
                                        
                                    }
                                    }}
                                    disabled={key === 'state'}
                                    />
                                {requiredFieldsError[key] && <Typography variant="body2" color="error">{requiredFieldsError[key]}</Typography>}
                                </React.Fragment>
                            )}
                            </Grid>
                        ))}
                        </Grid>
                        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                            <SubjectTable olympiad={olympiad} subjectList={subjectList} selectedSubjects={selectedSubjects} handleSubjectSelection={handleSubjectSelection}/>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ backgroundColor: '#ac2cc4', width: '10rem' }}
                            >
                                Submit
                            </Button>
                        </div>
                        {message && <p style={{color: 'red', margin: 'auto'}}>{message}</p>}
                        
                    </Box>
                </form>
                
        </div>
  )
}
